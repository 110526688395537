<template>
  <!--星级价格弹窗组件-->
  <div class="sp_pop">
    <div class="sp_out p_a" :class="isFull ? 'full_pop' : ''">
      <div class="sp_full_head d_f ali_c" v-if="isFull">
        <div class="cur_p" @click="closeSP">
          <img src="../img/close_sp.png" alt="">
        </div>
      </div>
      <div class="sp_full_title" v-if="isFull">
        价格(按照均价)
      </div>
      <div class="sp_out_head d_f ali_c j_b" v-if="!isFull">
        <span class="sph_title">价格(按照均价)</span>
        <div class="cur_p flex_1 t_r" @click="closeSP">
          <img src="../img/close_sp.png" alt="">
        </div>
      </div>
      <div class="sp_slide_out">
        <div class="d_f ali_c j_s sp_price">
          <span class="sp_price_red">￥{{slide.value[0]}}</span>
          <span>￥{{slide.value[1]}}</span>
        </div>
        <vue-slider
          :dotSize="[27,27]"
          :height="2"
          :min="slide.min"
          :max="slide.max"
          :lazy="true"
          tooltip="none"
          @dragging="dragSlide"
          @change="dragSlide"
          v-model="slide.value"
        ></vue-slider>
      </div>
      <div class="sp_price_out">
        <div class="sp_price_item t_a" :key="item.value" :class="acPrice === index ? 'ac' : ''" v-for="(item,index) in priceArr" @click="choosePrice(item,index)">{{item.name}}</div>
      </div>
      <div class="sp_star_out">
        <div class="sps_title">星级</div>
        <div class="sps_out">
          <div class="sp_price_item t_a" @click="changeState(item)" :class="item.acStar ? 'ac' : ''" v-for="(item,index) in starArr" :key="item.value">{{item.name}}</div>
        </div>
      </div>
      <div class="d_f ali_c sp_btn_out j_s">
        <div class="sp_btn t_a" @click="reset">重置</div>
        <div class="sp_btn t_a ac" @click="saveSP">完成</div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueSlider from 'vue-slider-component'
  import 'vue-slider-component/theme/default.css'
  import apis from '../utils/apis'

  export default {
    name: "starPrice",
    components: {
      'vue-slider':VueSlider
    },
    props:{
      isFull:{
        type: Boolean,
        default:true
      },
    },
    data() {
      return {
        slide:{
          value:[0,100],
          min:0,
          max:100
        },
        chooseSlideVal:[0,0],
        acPrice:0,
        priceArr:[],
        starArr:[],
        infoSP: sessionStorage.getItem('starPrice') ? JSON.parse(sessionStorage.getItem('starPrice')) : {}, // 选择金额星级
      }
    },
    mounted(){
      console.log(VueSlider)
      this.enumsList('price_range',this.delPrice);
      if(!this.infoSP.price){
        this.enumsList('price_range',this.delPrice);
        this.enumsList('star',this.delStar);
      }else{
        this.starArr = JSON.parse(JSON.stringify(this.infoSP.star))
      }
    },
    methods: {
      changeState(row){
        if(row.value == -1){
          this.starArr.map((item,index) => {
            if(item.value == row.value){
              item.acStar = !item.acStar;
            }else{
              item.acStar = false;
            }
          });
        }else{
          this.starArr.map((item,index) => {
            if(item.value == -1){
              item.acStar = false;
            }else if(item.value == row.value){
              item.acStar = !item.acStar;
            }
          });
        }
        this.starArr = JSON.parse(JSON.stringify(this.starArr));
      },
      reset(){
        this.choosePrice(this.priceArr[0],0);
        this.delStarArr();
        this.acPrice = 0;
        sessionStorage.removeItem('starPrice');
      },
      delStarArr(num){
        this.starArr.map((item,index) => {
          if(index === 0){
            item.acStar = true
          }else{
            item.acStar = false;
          }
        });
        this.starArr = JSON.parse(JSON.stringify(this.starArr));
      },
      delStar(info){
        this.starArr = JSON.parse(JSON.stringify(info));
        this.delStarArr()
      },
      delPrice(info){
        this.priceArr = JSON.parse(JSON.stringify(info));
        let num = 0;
        if(this.infoSP.price){
          this.priceArr.map((item,index) => {
            if(item.value == this.infoSP.price.join('-')){
              num = index
            }
          })
        }
        this.choosePrice(this.priceArr[num],num);
      },
      enumsList(key,callback){
        this.$get({
          url: apis.enumsList,
          params: {
            key:key
          }
        }).then(rsp => {
          callback(rsp)
        });
      },
      saveSP(){
        let arr = [];
        let info = {
          price:JSON.parse(JSON.stringify(this.chooseSlideVal)),
          star:this.starArr
        };
        sessionStorage.setItem('starPrice',JSON.stringify(info));
        this.$emit('save',info)
      },
      closeSP(){
        this.$emit('close')
      },
      dragSlide(val){
        this.chooseSlideVal = val;
      },
      choosePrice(row,index){
        this.acPrice = index;
        let arr = row.value.split('-');
        this.slide.value = JSON.parse(JSON.stringify([arr[0],arr[1]]));
        this.chooseSlideVal = JSON.parse(JSON.stringify([arr[0],arr[1]]));
        this.slide.min = Number(JSON.parse(JSON.stringify(arr[0])));
        this.slide.max = Number(JSON.parse(JSON.stringify(arr[1])));
      },
    }
  }
</script>

<style scoped lang="scss">
  .sp_pop{
    .sp_out{
      &.full_pop{
        .sp_btn_out{
          padding: 13px 21px;
          border-top: 1px solid #DEDEDE;
          box-sizing: border-box;
          .sp_btn{
            flex: unset;
            height:51px;
            border:1px solid rgba(160,160,160,1);
            border-radius:3px;
            line-height: 51px;
            width: 149px;
            color: #3E3E3E;
            &.ac{
              background:rgba(32,193,119,1);
              border-radius:3px;
              border:1px solid rgba(32,193,119,1);
            }
          }
        }
        .sp_full_title{
          margin: 30px 0 50px 0;
          font-size:17px;
          font-weight:500;
          color:rgba(30,30,30,1);
        }
        .sp_star_out .sps_title{
          font-size:17px;
          font-weight:500;
          color:rgba(30,30,30,1);
        }
        .sp_full_head{
          position: absolute;
          width: 100%;
          height: 51px;
          left: 0;
          top: 0;
          border-bottom: 1px solid #DEDEDE;
          padding-left: 22px;
          img{
            width: 17px;
          }
        }
        border-radius:0;
        height: 100vh;
        padding-top: 51px;
        padding-bottom: 76px;
      }
      border-radius:7px 7px 0px 0px;
      .sp_btn_out{
        width: 100%;
        bottom: 0;
        position: fixed;
        left: 0;
        .sp_btn{
          flex: 1;
          border-top: 1px solid #E0E0E0;
          height:36px;
          background:rgba(255,255,255,1);
          line-height: 36px;
          font-size:17px;
          font-weight:500;
          color:rgba(31,189,116,1);
          cursor: pointer;
          &.ac{
            background:rgba(31,189,116,1);
            border-top: 1px solid rgba(31,189,116,1);
            color: white;
          }
        }
      }
      .sp_star_out{
        .sps_out{
          .sp_price_item{
            margin: 19px 10px 0 0;
            &.ac{
              background: url("../img/sp_star_bg.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          &::after{
            content: ' ';
            display: block;
            clear: both;
          }
        }
        .sps_title{
          margin-top: 37px;
          font-size:14px;
          font-weight:500;
          color:rgba(110,110,110,1);
        }
        margin-bottom: 40px;
      }
      .sp_price_out{
        &::after{
          content: ' ';
          display: block;
          clear: both;
        }
      }
      .sp_price_item{
        float: left;
        margin: 15px 10px 0 0;
        /*width: 104px;*/
        width: calc((100% - 20px) / 3);
        color:rgba(62,62,62,1);
        height:35px;
        background:rgba(240,240,240,1);
        border-radius:3px;
        line-height: 35px;
        &.ac{
          background:rgba(215,248,233,1);
          color: #1FBD74;
        }
        &:nth-child(3n){
          margin-right: 0!important;
        }
      }
      .sp_slide_out{
        margin-bottom: 20px;
        .sp_price{
          font-size:13px;
          color: #3E3E3E;
          font-weight:500;
          /*margin-bottom: 5px;*/
          .sp_price_red{
            color:rgba(230,1,1,1);
          }
        }
      }
      .sp_out_head{
        .sph_title{
          font-size:14px;
          font-weight:500;
          color:rgba(110,110,110,1);
        }
        margin-bottom: 27px;
        img{
          width: 12px;
        }
      }
      box-sizing: border-box;
      width: 100%;
      bottom: 0;
      background-color: white;
      padding: 27px 22px 36px 22px;
      animation: goToTop .5s;
    }
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.51);
  }
  @keyframes goToTop
  {
    from {bottom: -100%;}
    to {bottom: 0;}
  }

</style>
