<template>
  <!--位置区域弹窗组件-->
  <div class="ca_out p_f">
    <div class="ca_title_out d_f ali_c">
      <div class="cur_p" @click="closeCA">
        <img src="../img/close_sp.png" alt="">
      </div>
      <div class="ca_title t_a flex_1">选择区域</div>
    </div>
    <section class="ca_content_out">
      <div class="d_f ca_top_location ali_c j_s">
        <div class="ctl_title">我的位置</div>
        <div class="ctl_location d_f ali_c" @click="getlocation">
          <img src="../img/location_reload.png" alt="">
          <span>重新定位</span>
        </div>
      </div>
      <div class="d_f ali_c ca_address">
        <img src="../img/ca_address.png" alt="">
        <span>{{areaName || '峨眉山市'}}</span>
      </div>
      <div class="ca_c_addr_out">
        <div class="caca_title">当前位置</div>
        <div class="caca_addr t_a d_i ac">{{areaNameChoose || '峨眉山市'}}</div>
      </div>
      <div class="ca_c_addr_out ca_all_addr_out">
        <div class="caca_title">全部区域</div>
        <div>
          <div class="caca_addr t_a d_i" v-for="(item,index) in citys" :key="index" @click="changeArea(item)">{{item.name}}</div>
        </div>
      </div>
    </section>
    <div id="mapContainer" v-show="false"></div>
  </div>
</template>

<script>
  import apis from '../utils/apis'
  import { Toast } from 'vant'

  export default {
    name: "chooseArea",
    data() {
      return {
        areaName:'',
        areaNameChoose:sessionStorage.getItem('area') ? JSON.parse(sessionStorage.getItem('area')).name : '峨眉山市',
        citys:[],
      }
    },
    mounted(){
      this.loadCitys()
    },
    methods: {
      closeCA(){
        this.$emit('close')
      },
      changeArea(row){
        this.areaNameChoose = row.name;
        sessionStorage.setItem('area',JSON.stringify(row));
        this.closeCA();
      },
      //获取所有城市
      loadCitys() { //获取热门城市
        let me = this;
        me.$get({
          url: apis.citys,
          params: {
            adCode: 'qy-ls'
          }
        }).then(rsp => {
          if (rsp && rsp.length > 0) {
            me.citys = rsp;
          }
        });
      },
      //获取当前位置done
      getlocation(){
        let _t = this;
        AMap.plugin('AMap.Geolocation', function() {
          var geolocation = new AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: true,
            // 设置定位超时时间，默认：无穷大
            timeout: 10000,
            // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
            buttonOffset: new AMap.Pixel(10, 20),
            //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            zoomToAccuracy: true,
            //  定位按钮的排放位置,  RB表示右下
            buttonPosition: 'RB'
          })

          geolocation.getCurrentPosition()
          AMap.event.addListener(geolocation, 'complete', onComplete)
          AMap.event.addListener(geolocation, 'error', onError)

          function onComplete (data) {
            // data是具体的定位信息
            _t.areaName = data.addressComponent.district;
          }
          function onError (data) {
            _t.areaName = '峨眉山市';
            Toast('定位失败');
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  .ca_out{
    .ca_content_out{
      .ca_c_addr_out{
        &.ca_all_addr_out{
          .caca_addr{
            margin-bottom: 20px;
            margin-right: 15px;
          }
        }
        .caca_addr{
          width: calc((100% - 30px) / 3);
          height:38px;
          border-radius:2px;
          line-height: 38px;
          font-size:12px;
          font-weight:500;
          margin-bottom: 29px;
          box-sizing: border-box;
          color: #3E3E3E;
          background:rgba(244,244,244,1);
          border:1px solid rgb(222, 222, 222);
          &:nth-child(3n){
            margin-right: 0;
          }
          &.ac{
            background:rgba(32,193,119,1);
            border:1px solid rgba(32,193,119,1);
            color:rgba(255,255,255,1);
          }
        }
        .caca_title{
          font-size:15px;
          font-weight:bold;
          color:rgba(62,62,62,1);
          margin-bottom: 15px;
        }
      }
      .ca_address{
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
        padding-bottom: 25px;
        border-bottom: 1px solid #D0D0D0;
        margin-bottom: 18px;
        img{
          margin-right: 6px;
          width: 10px;
        }
      }
      .ca_top_location{
        font-size:15px;
        font-weight:bold;
        color:rgba(62,62,62,1);
        margin-bottom: 22px;
        .ctl_location{
          span{
            font-weight:500;
            color:rgba(32,193,119,1);
          }
          img{
            margin-right: 6px;
            width: 13px;
          }
        }
      }
      padding: 21px;
    }
    height: 100vh;
    background-color: white;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99;
  }
</style>
